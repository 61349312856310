import React from "react";
import { Link, graphql } from "gatsby";
import { Box } from "@chakra-ui/react";
const TagsList = ({ data }) => {
  const { tags } = data.allMdx;
  return (
    <div>
      <h1>タグ一覧</h1>
      <ul>
        {tags.map((tag) => {
          return (
            <li key={tag.fieldValue}>
              <Link to={tag.fieldValue}>{tag.fieldValue}</Link>
            </li>
          );
        })}
      </ul>
      <Box mt={"3px"}>
        <Link to="/">ホームへ戻る</Link>
      </Box>
    </div>
  );
};
export default TagsList;
export const pageQuery = graphql`
  query {
    allMdx {
      tags: group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`;
